<template>
  <div class="RegistrationOrder_I">
    <div class="headNavs">
      <div class="headTilte">报名订单</div>
      <div class="headDetails">说明：包含订单编号、买家昵称、商品名称、数量、金额、状态</div>
    </div>


    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入客户姓名/联系电话">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
          :data="tableData"
          class="table"
          header-cell-class-name="table-header"
          @selection-change="handleSelect">
          <el-table-column prop="sign_id" label="ID" width="65" align="center"></el-table-column>
          <el-table-column prop="username" label="客户姓名"  align="center"></el-table-column>
          <el-table-column prop="phone" label="联系电话"  align="center"></el-table-column>
          <el-table-column prop="goods_name" label="咨询商品"  align="center" width="300">
            <template #default="scope">
              <span>{{ scope.row.goods ? scope.row.goods.goods_name : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="business_id" label="服务商"  align="center">
            <template #default="scope">
              <span>{{ scope.row.business ? scope.row.business.username : '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type_text" label="类型"  align="center"></el-table-column>
          <el-table-column prop="is_solve_text" label="状态" align="center">
            <template #default="scope">
              <div v-if="scope.row.is_solve == 0">未解决</div>
              <div v-if="scope.row.is_solve == 1">已完成</div>
            </template>
          </el-table-column>
          <el-table-column prop="createtime" label="创建时间"  align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="100">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  v-if="scope.row.is_solve == 1"
                  disabled
                  class="caese"
                  type="text"
              >已完成
              </el-button>
              <el-button
              v-if="scope.row.is_solve == 0"
                  type="text"
                  @click="confirmAdd(scope.row)"
              >确认完成
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      total:'',
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
      },
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/goods/sign/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search.keyword = ""
      }
      that.loadTable();
    },
     //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }, 
    //确认完成
    confirmAdd(data){
      var that = this
      let dataId = data.sign_id
      axios({
        method: 'POST',
        url: '/admin/goods/sign/finish',
        data: {
          id: data.sign_id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    }
  }
}
</script>
